<template>
  <div class="d-flex justify-content-center full-height full-width align-items-center">
    <div class="error-container text-center">
      <!--      <h1 class="error-number">404</h1>-->
      <h2 class="semi-bold">{{msg}}</h2> <br/><br/><br/><br/>
<!--      <router-link :to="{ name: ROUTES.ADMIN.ALL_USERS_LIST,-->
<!--          query: {-->
<!--            'filters.apartment_id.equal' : 'all',-->
<!--            'filters.status_enabled.equal' : 'all'-->
<!--          }}"  @click="refreshToken" style="text-decoration: underline">管理画面へ</router-link>-->
      <p @click="refreshToken" style="text-decoration: underline; color: #007bff;cursor: pointer">管理画面へ</p>
    </div>
  </div>
</template>

<script>
import {ADMIN_URL_API, ENDPOINT} from "@constants/api";
import {logoutAction} from "@utils";
import {APP_NAME} from "@constants";
import {store} from "@store";

export default {
  name: "VerifySuccess",
  data() {
    return {
      msg: "Eメールアドレスの確認が完了しました。",
      reloadPage : false,
      invalidToken : true,
    }
  },

  async mounted() {

  },
  methods : {
    async refreshToken() {
      let response = await this.$request.post(
        ADMIN_URL_API +  ENDPOINT.AUTH_USER,
        {}, {
          headers: {
            Authorization: 'Bearer ' + this.$route.query.token
          }
        }
      );

      if (!response.hasErrors()) {
        this.invalidToken = false
        store.commit('auth/setAuthRole', "admin")
        let token = this.$store.getters["auth/getToken"];
        await this.$request.delete(ADMIN_URL_API +  ENDPOINT.AUTH_LOGOUT, {} , {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
        this.$store.commit("auth/removeAuth")
        this.$store.commit(`auth/updateAuth`, {
          user: response.data.data,
          token: this.$route.query.token,
          expire_time: this.$route.query.expired,
        });
      }

      if (this.$route.query.showLoginPage) {
        this.invalidToken = false
        this.__noticeError("ログインに失敗しました。ログイン情報をご確認いただき、再度ログインをお試しください。");
        return ;
      }

      window.location.href = "/admin/accounts?filters.apartment_id.equal=all&filters.status_enabled.equal=all"
    },
  }
}
</script>
